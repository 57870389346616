<template>
  <PreparingResult v-if="loading" @finish-loading="turnOffLoading"/>
  <Content v-else>
    <DetailsStrengthsModal
      v-if="detailsElement"
      :showing-modal="showModal"
      @show-modal="closeModal($event)"
      :strength-info="detailsElement"/>
    <div class="self_answer">
      <div class="сongratulation-completed-survey">
        <div class="content-text">
          <div class="result-button-basic"
               :class="{'result-change-button': myResult}">
            <span>STRENGTHS</span>
          </div>
          <div class="result-button-basic"
               :class="{'result-change-button': myResult}">
            <span>&nbsp;</span>
          </div>
        </div>
        <div class="questionnaire-results" v-show="myResult">
          <div class="title-expand-invitation">
            <div class="title-you-result">YOURS</div>
            <div class="title-crowd-result"
                 v-if="getGeneralSurveyData.numberOfTimesCompetedQuiz === 2">
              {{ getGeneralSurveyData.roleName.toUpperCase() }}
            </div>
            <div class="title-crowd-result" v-else>
              &nbsp;
            </div>
          </div>
          <div v-for="(item, index) in strengthConversionScoreArray"
               :key="item.id" class="general-block-with-strengths-bars">
            <div class="block-result" @click="showExplainModal(item.name)">
              <StrengthsBarResult
                :width="item.percentage"
                :strengthName="item.name"
                :color="item.color"
                :filter="item.filter"
                :result-is-not-ready="item.resultIsNotReady"
                :is-crowd-result="false"
              />
            </div>
            <div class="block-result"
                 v-if="getGeneralSurveyData.numberOfTimesCompetedQuiz === 2"
                 @click="showExplainModal(strengthConversionScoreArraySecondaryResult[index].name)">
              <StrengthsBarResult
                :width="strengthConversionScoreArraySecondaryResult[index].percentage"
                :strengthName="strengthConversionScoreArraySecondaryResult[index].name"
                :color="strengthConversionScoreArraySecondaryResult[index].color"
                :filter="strengthConversionScoreArraySecondaryResult[index].filter"
                :resultIsNotReady="strengthConversionScoreArraySecondaryResult[index]
                          .resultIsNotReady"
                :is-crowd-result="true"
              />
            </div>
          </div>
        </div>
        <div class="continuation-code">
          <div class="continuation-code-text">
            CLICK THE CONTINUATION CODE #{{ getGeneralSurveyData.numberOfTimesCompetedQuiz }}
            <br/>BELOW AND PASTE IT BACK INTO GOOGLE FORMS TO CONTINUE
          </div>
          <v-tooltip
            ref="tooltip"
            v-model="showToolTip"
            :open-on-focus="false"
            :open-on-hover="false"
            top>
            <template v-slot:activator="{ on, attrs }">
              <div class="continuation-code-code"
                   v-on="on"
                   @click="copyCode(on)">
                {{ getGeneralSurveyData.numberOfTimesCompetedQuiz === 2 ?
                getGeneralSurveyData.continuationCode2 : getGeneralSurveyData.continuationCode1 }}
              </div>
            </template>
            <span>{{messageTooltip}}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import helpFunction from '@helpers/helpFunction';
import PreparingResult from '@innerworks_ds/innerworks-storybook-main/src/stories/PreparingResult/PreparingResult.vue';
import StrengthsBarResult from '@innerworks_ds/innerworks-storybook-main/src/stories/StrengthsBarResult/StrengthsBarResult.vue';
import emptySupporters from '@assets/supportees-not-result.svg';
import DetailsStrengthsModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DetailStrengthsModal/DetailsStrengthsModal.vue';
import { mapGetters } from 'vuex';
import copy from 'copy-to-clipboard';

export default {
  components: {
    DetailsStrengthsModal,
    StrengthsBarResult,
    PreparingResult,
  },
  name: 'Report',
  data: () => ({
    strengthConversionScoreObject: {},
    strengthConversionScoreForOther: {},
    respondentsCount: 0,
    emptySupporters,
    showModal: false,
    detailsElement: null,
    strengthDescription: [],
    link: '',
    buttonCopyLinkText: 'INVITE SUPPORTERS',
    linkCopied: false,
    isOthersAmount: false,
    invitationResult: [],
    loading: true,
    dialog: false,
    messageTooltip: 'Copied',
    showToolTip: false,
  }),
  watch: {
    getProfile(newValue) {
      if (newValue.token) {
        this.loading = false;
        this.fetchPersonalityTypeReport();
      }
    },
    selectedOptions(value) {
      this.otherAmountCalculate(this.otherLastResult, value);
    },
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getPersonalityTest: 'invitation/getPersonalityTest',
      myResult: 'behaviorComponents/isNotCrowdTabResultPage',
      getGeneralSurveyData: 'surveyData/getGeneralSurveyData',
    }),
    showButtons() {
      return (this.getProfile.registeredFromCSV && this.showButtonAskContactsForInvitation)
        || !this.getProfile.registeredFromCSV;
    },
    userRegisteredFromCSV() {
      return this.getProfile.registeredFromCSV;
    },
    isAuthUser() {
      return this.getProfile.token;
    },
    strengthConversionScoreArray() {
      return helpFunction.strengthConversionScore(this.strengthConversionScoreObject, '#320959', 'invert(61%) sepia(6%) saturate(2376%) hue-rotate(227deg) brightness(88%) contrast(90%)', this.respondentsCount, true, true);
    },
    strengthConversionScoreArraySecondaryResult() {
      return helpFunction.strengthConversionScore(this.strengthConversionScoreSecondaryResult, '#FF5151', 'invert(93%) sepia(63%) saturate(3313%) hue-rotate(295deg) brightness(104%) contrast(93%)', this.respondentsCount, true, true);
    },
  },
  created() {
    this.$store.dispatch('showComponent/showHeaderMain');
    this.fetchPersonalityTypeReport();
    this.$store.dispatch('surveyData/updateSurveyData', this.getGeneralSurveyData.prolificId);
  },
  methods: {
    closeShareLinkModal(value) {
      this.dialog = value;
    },
    closeModal(value) {
      this.showModal = value;
      this.detailsElement = null;
    },
    getStrengthsContent(name) {
      const result = this.strengthDescription.find(element => element.name === name);
      if (result) this.detailsElement = result;
      return undefined;
    },
    showExplainModal(item) {
      this.getStrengthsContent(item);
      this.showModal = true;
    },
    fetchPersonalityTypeReport() {
      this.loading = true;
      this.$api.strengthDescription.getStrengthDescription()
        .then((x) => {
          this.strengthDescription = x.strengthContents;
        });

      const prolificID = this.getGeneralSurveyData.prolificId;

      this.$api.questionnaireCutVersion.getQuestionnaireResult(prolificID,
        this.getGeneralSurveyData.questionnaireCutVersionId)
        .then((res) => {
          this.strengthConversionScoreObject = res.strengthConversionScore;
          this.strengthConversionScoreSecondaryResult = res.strengthConversionScoreCutVersionSecond;
        }).catch(() => {
          this.$store.dispatch('showComponent/gotToStepInitial');
        });
    },
    copyCode() {
      const code = this.getGeneralSurveyData.numberOfTimesCompetedQuiz === 2
        ? this.getGeneralSurveyData.continuationCode2 : this.getGeneralSurveyData.continuationCode1;
      copy(code);
      setTimeout(() => {
        this.showToolTip = false;
      }, 1000);
    },
    generateUniqueId() {
      const generalSurveyData = this.getGeneralSurveyData;
      let uniqueId;

      if (generalSurveyData.numberOfTimesCompetedQuiz === 0) {
        uniqueId = generalSurveyData.questionnaireId1;
      } else {
        uniqueId = generalSurveyData.questionnaireId2;
      }

      return uniqueId;
    },
    turnOffLoading() {
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>

.button_expand {
  background-color: #ffffff;
  color: #a158d9;
  border-radius: 40px;
}

.v-expansion-panel-header {
  width: 100%;
  border-radius: 12px;
  padding: 13px;
}

.sub-title {
  font-size: 35px;
}

.you-strengths {
  width: 50%;
}

.strengths-result {
  width: 50%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.v-application .flex-row-reverse {
  flex-direction: row !important;
}

.v-expansion-panel:before {
  box-shadow: none;
  padding: 0%;
  background: white;
}

.general-block-with-strengths-bars {
  display: flex;
  flex-direction: row;
}


.block-result {
  width: 50%;
  cursor: pointer;
  margin-bottom: 5px;
}

.continuation-code {
  background: #FF5151;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;


  .continuation-code-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .continuation-code-code {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    padding-bottom: 17px;
    cursor: pointer;
  }
}
.strengths-result {
  width: 100%;
}

.title-expand-invitation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.self_answer {
  font-family: $newDefaultFont;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .content-text {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #7811C9;
    flex-direction: row;

    .result-change-button {
      border-bottom: 3px solid #7811C9;
      display: block;
    }

    .result-button-basic {
      width: 100%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 15px 10px 13px;
    }
  }

  .сongratulation-completed-survey {
    text-align: center;
    margin-top: 40px;
  }

  .questionnaire-results {
    display: flex;
    width: 100%;
    margin-top: 30px;
    padding-left: 25px;
    padding-right: 20px;
    flex-direction: column;
  }
}

.title-you-result {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #320959;
  margin-bottom: 20px;
  text-align: center;
  width: 50%;
}

.title-crowd-result {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #FF5151;
  margin-bottom: 20px;
  text-align: center;
  width: 50%;
}
.questionnaire-results-supportees {
  margin-top: 35px;
  margin-left: 25px;
  margin-right: 25px;
  overflow-y: auto;
}

.question-results-addaptive {
  height: 40vh;
}

@media (max-height: 600px) {
  .question-results-addaptive {
    height: 190px !important;
  }
}

@media (max-width: 380px) and (max-height: 750px) {
  .question-results-addaptive {
    height: 250px !important;
  }
}

@media (max-width: 380px) and (max-height: 680px) {
  .question-results-addaptive {
    height: 200px !important;
  }
}

@media (max-width: 300px) {
  .question-results-addaptive {
    height: 150px !important;
  }
}

.bottom-part-of-site {
  position: fixed;
  bottom: 0;
  max-width: 480px;
  width: 100%;
}

.space-class {
  height: 155px;
}

.space-class-second {
  height: 102px;
}

@media (max-height: 820px) {
  .title-crowd {
    margin-top: 0 !important;
  }
}
.title-expand-invitation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.title-you-result {
  margin-bottom: 21px;
}

.title-crowd-result {
  margin-bottom: 21px;
}

</style>
