<template>
  <ResultCutVersion/>
</template>

<script>
import ResultCutVersion from '@components/ResultCutVersion/ResultCutVersion.vue';

export default {
  components: {
    ResultCutVersion,
  },
  name: 'Report',
};
</script>
<style lang="scss" scoped>

</style>
